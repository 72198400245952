<template>
  <div class="tag">
    {{ tagName }}
    <span
      v-if="deleteAction"
      class="delete"
      @click="emit('delete')"
    >
      <v-icon>mdi-close</v-icon>
    </span>
  </div>
</template>

<script setup lang="ts">
const { tagName, fontColor, deleteAction } = defineProps({
  tagName: String,
  fontColor: {
    type: String,
    default: 'white',
  },
  deleteAction: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['delete'])
</script>

<style lang="scss" scoped>
.tag {
  font-size: 14px;
  letter-spacing: 1.4px;
  line-height: normal;
  gap: 0.25rem;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0.375rem 0.5rem;
  color: v-bind(fontColor);
  border: 1px solid #4c4d58;

  > .delete {
    cursor: pointer;
  }
}
</style>
